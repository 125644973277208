@font-face {
  font-family: 'Tangerine';
  src: url('fonts/TangerineRegular.woff2') format('woff2'),
      url('fonts/TangerineRegular.woff') format('woff'),
      url('fonts/TangerineRegular.ttf') format('truetype'),
      url('fonts/Tangerine.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --box-shadow: 0px 0px 5px rgba(162, 37, 68, 0.1);
  --circle-size: 90%;
  --dark-color: rgb(162, 37, 68);
  --light-color: rgb(252, 248, 217);
  --text-headline: "Tangerine", sans-serif;
  --text-paragraph: "Red Hat Display", sans-serif;
  --transparent: rgba(255, 255, 255, 0);
}

.light-background {
  background: linear-gradient(0deg, rgba(255, 253, 240), rgba(253, 212, 164, 0.75));
}

.dark-background {
  background: linear-gradient(0deg, rgb(65, 0, 51), rgba(196, 55, 90, 0.6));
}

.text-light-mode {
  color: var(--dark-color);
}

.text-dark-mode {
  color: var(--light-color);
}

.outline-light-mode {
  color: var(--transparent);
  -webkit-text-stroke: 1px var(--dark-color);
}

.outline-dark-mode {
  color: var(--transparent);
  -webkit-text-stroke: 0.75px var(--light-color);
}

.link {
  font-family: var(--text-paragraph);
  font-size: 12px;
  font-weight: 500;
  padding: 7px 24px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

.links-light-mode {
  backdrop-filter: blur(2px);
  box-shadow: inset 0 0 20px rgba(252, 248, 219, 0.15), var(--box-shadow);
  color: var(--dark-color);
}

.links-dark-mode {
  backdrop-filter: blur(2px);
  box-shadow: inset 0 0 20px rgba(196, 55, 90, 0.25), 0px 0px 10px rgba(53, 0, 13, 0.1);
  color: var(--light-color);
}

.links-light-mode:hover {
  transform: scale(1.1);
}

.links-dark-mode:hover {
  transform: scale(1.1);
}

.space {
  letter-spacing: -1px;
}

.more-space {
  letter-spacing: 0;
}

.hidden {
  display: none;
}

.float-up {
  animation: floatUp 1.5s;
}

@keyframes floatUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.float-down {
  animation: floatDown 1.5s;
}

@keyframes floatDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.float-right {
  animation: floatRight 1.5s;
}

@keyframes floatRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.float-left {
  animation: floatLeft 1.5s;
}

@keyframes floatLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.float-out-rightside {
  animation: floatOutRightSide 1s;
}

@keyframes floatOutRightSide {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.float-out-leftside {
  animation: floatOutLeftSide 1s;
}

@keyframes floatOutLeftSide {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 1.5s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.gradients-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -2;
}
 
.g1,
.g2,
.g3,
.g4,
.g5 {
  position: absolute;
  height: var(--circle-size);
  width: var(--circle-size);
  top: calc(50% - var(--circle-size) / 2);
  left: calc(50% - var(--circle-size) / 2);
}

.g1 {
  animation: moveVertical 30s ease infinite;
  background: radial-gradient(circle at center, rgba(230, 187, 255, 0.8) 0, rgba(230, 187, 255, 0) 30%) no-repeat;
  opacity: 0.4;
  transform-origin: center center;
}

.g2 {
  animation: moveInCircle 20s reverse infinite;
  background: radial-gradient(circle at center, rgba(162, 37, 68, 0.8) 0, rgba(162, 37, 68, 0) 30%) no-repeat;
  opacity: 0.1;
  transform-origin: calc(50% - 800px);
}

.g3 {
  animation: moveInCircle 40s linear infinite;
  background: radial-gradient(circle at center, rgba(253, 154, 192, 0.8) 0, rgba(253, 154, 192, 0) 30%) no-repeat;
  opacity: 0.5;
  top: calc(50% - var(--circle-size) / 2 + 300px);
  left: calc(50% - var(--circle-size) / 2 - 600px);
  transform-origin: calc(50% + 800px);
}

.g4 {
  animation: moveHorizontal 40s ease infinite;
  background: radial-gradient(circle at center, rgba(250, 180, 180, 0.8) 0, rgba(250, 180, 180, 0) 30%) no-repeat;
  opacity: 0.5;
  transform-origin: calc(50% - 300px);
}

.g5 {
  animation: moveInCircle 20s ease infinite;
  background: radial-gradient(circle at center, rgba(193, 103, 121, 0.8) 0, rgba(193, 103, 121, 0) 30%) no-repeat;
  height: calc(var(--circle-size) * 2);
  width: calc(var(--circle-size) * 2);
  opacity: 0.4;
  top: calc(50% - var(--circle-size));
  left: calc(50% - var(--circle-size));
  transform-origin: calc(50% - 1000px) calc(50% + 300px);
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.grain {
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateZ(0);
  z-index: -1;
}

.grain::before {
  animation: noise 7s steps(10) infinite;
  background-image: url(grain.jpg);
  background-repeat: repeat;
  background-size: 200px;
  content: "";
  filter: var(--filter);
  height: 600%;
  width: 600%;
  opacity: var(--opacity);
  pointer-events: none;
  position: fixed;
  top: -300%;
  left: -150%;
  z-index: 9999;
}

@keyframes noise {
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%);
  }
  70% {
    transform: translateY(15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
  100% {
    transform: translate(0);
  }
}

@media (max-width: 800px) {
.grain::before {
  background-size: 140px;
}
}