.ContactPage {
  margin: 11.5% 20px;
}

.ContactPage h2 {
  font-family: var(--text-headline);
  font-size: 55px;
  letter-spacing: -2px;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.ContactPage p {
  font-family: var(--text-paragraph);
  font-size: 12px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

.ContactPage a {
  margin: 0 0 10px 10px;
}

@media (max-width: 400px) {
.ContactPage {
  margin: 165px 10px;
}

.ContactPage h2 {
  font-size: 45px;
}

.ContactPage p,
.ContactPage a {
  font-size: 11px;
}
}