.MenuPage {
  display: flex;
  justify-content: center;
  margin: 3.5% 30% 0 0;
}

.MenuPage ul {
  list-style: none;
}

.MenuPage li {
  font-family: var(--text-paragraph);
  font-size: 12px;
  font-weight: 900;
  letter-spacing: -1px;
  transition: all 300ms ease-in-out;
}

.MenuPage li:hover {
  color: var(--transparent);
  cursor: pointer;
  transform: scale(1.1);
}

.MenuPage button {
  background: var(--transparent);
  border: none;
  font-family: var(--text-headline);
  font-size: 55px;
  letter-spacing: -3px;
  padding: 0;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.MenuPage .gallery-btn {
  letter-spacing: -2px;
}

.MenuPage .contact-btn {
  letter-spacing: -2px;
}

@media (max-width: 800px) {
.MenuPage {
  justify-content: left;
  margin: 50px 0 0 30px;
}
}

@media (max-width: 500px) {
.MenuPage button {
  font-size: 45px;
  letter-spacing: -2px;
}
}

@media (max-width: 400px) {
.MenuPage {
  margin: 50px 0 0 0;
}
}
