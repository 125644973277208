.GalleryPage {
  margin: 7.5% auto 0 auto;
}

.GalleryPage h2 {
  font-family: var(--text-headline);
  font-size: 55px;
  letter-spacing: -2px;
  margin-bottom: 25px;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.GalleryPage .grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.GalleryPage .gallery-headline {
  font-family: var(--text-paragraph);
  font-size: 28px;
  letter-spacing: -1.5px;
  line-height: 18px;
  text-transform: lowercase;
}

.GalleryPage .gallery-paragraph {
  font-family: var(--text-paragraph);
  font-size: 11.5px;
  font-weight: 500;
  line-height: 0;
  text-transform: uppercase;
}

.GalleryPage button {
  border: none;
  padding: 115px 0;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.GalleryPage button:hover {
  background-size: 105%;
  color: rgba(252, 248, 217, 0.2);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.GalleryPage .gallery-border-light-mode {
  box-shadow: var(--box-shadow);
}

.GalleryPage .gallery-border-dark-mode {
  box-shadow: 0px 0px 5px rgba(53, 0, 13, 0.1);
}

.GalleryPage .greenwich-market-light-mode {
  background-image: linear-gradient(
    rgba(255, 237, 216, 0.9),
    rgba(255, 237, 216, 0.9)
  ),url(gallery/greenwich-market.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--dark-color);
}

.GalleryPage .greenwich-market-dark-mode {
  background-image: linear-gradient(
      rgba(114, 25, 47, 0.9),
      rgba(114, 25, 95, 0.9)
    ),
    url(gallery/greenwich-market.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--light-color);
}

.GalleryPage .ehla-eat-light-mode {
  background-image: linear-gradient(
    rgba(255, 237, 216, 0.9),
    rgba(255, 237, 216, 0.9)
    ),
    url(gallery/ehla-eat.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--dark-color);
}

.GalleryPage .ehla-eat-dark-mode {
  background-image: linear-gradient(
    rgba(114, 25, 47, 0.9),
    rgba(114, 25, 95, 0.9)
    ),
    url(gallery/ehla-eat.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--light-color);
}

.GalleryPage .ryan-london-light-mode {
  background-image: linear-gradient(
    rgba(255, 237, 216, 0.9),
    rgba(255, 237, 216, 0.9)
    ),
    url(gallery/ryan-london.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--dark-color);
}

.GalleryPage .ryan-london-dark-mode {
  background-image: linear-gradient(
    rgba(114, 25, 47, 0.9),
    rgba(114, 25, 95, 0.9)
    ),
    url(gallery/ryan-london.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--light-color);
}

.GalleryPage .live-music-light-mode {
  background-image: linear-gradient(
    rgba(255, 237, 216, 0.9),
    rgba(255, 237, 216, 0.9)
    ),
    url(gallery/music.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--dark-color);
}

.GalleryPage .live-music-dark-mode {
  background-image: linear-gradient(
    rgba(114, 25, 47, 0.9),
    rgba(114, 25, 95, 0.9)
    ),
    url(gallery/music.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--light-color);
}

.GalleryPage .greenwich-market:hover {
  background-image: url(gallery/greenwich-market.webp);
}

.GalleryPage .ehla-eat:hover {
  background-image: url(gallery/ehla-eat.webp);
}

.GalleryPage .ryan-london:hover {
  background-image: url(gallery/ryan-london.webp);
}

.GalleryPage .live-music:hover {
  background-image: url(gallery/music.webp);
}

.GalleryPage .gallery-btn-light {
  color: var(--dark-color);
}

.GalleryPage .gallery-btn-dark {
  color: var(--light-color);
}

/* Divider between GalleryPage and GalleryOne, Two, Three & Four */
.GalleryOne .grid,
.GalleryTwo .grid,
.GalleryThree .grid,
.GalleryFour .grid {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  margin-top: -100px;
  margin-bottom: 25px;
}

.GalleryOne h3,
.GalleryTwo h3,
.GalleryThree h3,
.GalleryFour h3 {
  font-family: var(--text-paragraph);
  font-size: 30px;
  letter-spacing: -1.5px;
  line-height: 10px;
  margin-top: 40px;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.GalleryOne p,
.GalleryTwo p,
.GalleryThree p,
.GalleryFour p {
  font-family: var(--text-paragraph);
  font-size: 11.5px;
  font-weight: 500;
  line-height: 10px;
  margin-bottom: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

.GalleryOne .grid button,
.GalleryTwo .grid button,
.GalleryThree .grid button,
.GalleryFour .grid button {
  font-size: 40px;
  margin: 0 10px;
  opacity: 0.5;
  padding: 40px 70px 0 70px;
  transition: all 300ms ease-in-out;
}

.GalleryOne .grid button:hover,
.GalleryTwo .grid button:hover,
.GalleryThree .grid button:hover,
.GalleryFour .grid button:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.1);
}

.GalleryOne .transparent,
.GalleryFour .transparent {
  color: rgba(255, 255, 255, 0);
}

.GalleryOne .top-btn-light,
.GalleryTwo .top-btn-light,
.GalleryThree .top-btn-light,
.GalleryFour .top-btn-light {
  animation: backToTop 1500ms infinite;
  border: 1px solid var(--light-color);
  border-radius: 50% 50% 0 0;
  color: var(--light-color);
  font-size: 40px;
  padding: 0 12px;
  position: fixed;
  left: 50%;
  bottom: -2px;
  opacity: 0.75;
  text-decoration: underline;
  transform: translateX(-50%);
  transition: all 300ms ease-in-out;
}

.GalleryOne .top-btn-dark,
.GalleryTwo .top-btn-dark,
.GalleryThree .top-btn-dark,
.GalleryFour .top-btn-dark {
  animation: backToTop 1500ms infinite;
  border: 1px solid var(--dark-color);
  border-radius: 50% 50% 0 0;
  color: var(--dark-color);
  font-size: 40px;
  padding: 0 12px;
  position: fixed;
  left: 50%;
  bottom: -2px;
  opacity: 0.75;
  text-decoration: underline;
  transform: translateX(-50%);
  transition: all 300ms ease-in-out;
}

.GalleryOne a:hover,
.GalleryTwo a:hover,
.GalleryThree a:hover,
.GalleryFour a:hover {
  cursor: pointer;
  opacity: 1;
}

.GalleryOne .gallery-container,
.GalleryTwo .gallery-container,
.GalleryThree .gallery-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  transition: all 300ms ease-in-out;
}

.GalleryFour .gallery-container-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: all 300ms ease-in-out;
}

@keyframes backToTop {
  0% {
    transform: translateY(0%) translateX(-50%);
  }
  50% {
    transform: translateY(10%) translateX(-50%) scale(1.1);
  }
  100% {
    transform: translateY(0%) translateX(-50%);
  }
}

@media (max-width: 1200px) {
.GalleryPage .grid {
  grid-template-columns: 1fr 1fr;
}

.GalleryOne .grid,
.GalleryTwo .grid,
.GalleryThree .grid,
.GalleryFour .grid {
  grid-template-columns: 1fr 10fr 1fr;
  margin-top: -75px;
}
}

@media (max-width: 1000px) {
.GalleryOne .gallery-container,
.GalleryTwo .gallery-container,
.GalleryThree .gallery-container {
  grid-template-columns: 1fr 1fr 1fr;
}
}

@media (max-width: 800px) {
.GalleryPage {
  margin: 25px 0 0 0;
}

.GalleryPage .grid {
  grid-template-columns: 1fr;
}

.GalleryPage .gallery-headline {
  font-size: 25px;
}

.GalleryPage .gallery-paragraph {
  font-size: 10px;
}

.GalleryOne .grid,
.GalleryTwo .grid,
.GalleryThree .grid,
.GalleryFour .grid {
  grid-template-columns: 1fr 10fr 1fr;
  margin-top: -25px;
}

.GalleryOne .grid button,
.GalleryTwo .grid button,
.GalleryThree .grid button,
.GalleryFour .grid button {
  padding: 40px 30px 0 30px;
}

.GalleryOne .gallery-container,
.GalleryTwo .gallery-container,
.GalleryThree .gallery-container {
  grid-template-columns: repeat(2, 1fr);
}

.GalleryFour .gallery-container-2 {
  grid-template-columns: repeat(2, 1fr);
}
}

@media (max-width: 500px) {
.GalleryOne h3,
.GalleryTwo h3,
.GalleryThree h3,
.GalleryFour h3 {
  font-size: 23px;
  line-height: 5px;
}

.GalleryOne p,
.GalleryTwo p,
.GalleryThree p,
.GalleryFour p {
  font-size: 9.5px;
  line-height: 9px;
}
}

@media (max-width: 400px) {
.GalleryPage h2 {
  font-size: 45px;
}

.GalleryPage .gallery-headline {
  font-size: 23px;
  line-height: 14px;
}

.GalleryPage .gallery-paragraph {
  font-size: 9.5px;
}

.GalleryOne .grid,
.GalleryTwo .grid,
.GalleryThree .grid,
.GalleryFour .grid {
  grid-template-columns: 1fr 10fr 1fr;
  margin-top: -30px;
  margin-bottom: 10px;
}

.GalleryOne .grid button,
.GalleryTwo .grid button,
.GalleryThree .grid button,
.GalleryFour .grid button {
  padding: 40px 10px 0 10px;
}

.GalleryOne .gallery-container,
.GalleryTwo .gallery-container,
.GalleryThree .gallery-container {
  grid-template-columns: 1fr;
}

.GalleryFour .gallery-container-2 {
  grid-template-columns: 1fr;
}
}