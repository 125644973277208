.AboutPage {
  margin: 9.5% auto;
  max-width: 850px;
  padding: 0 20px;
}

.AboutPage h2 {
  font-family: var(--text-headline);
  font-size: 55px;
  letter-spacing: -3px;
  margin-right: 2px;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.AboutPage p {
  font-family: var(--text-paragraph);
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 20px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

@media (max-width: 400px) {
.AboutPage {
  margin: 100px 10px;
  padding: 0 10px;
}

.AboutPage h2 {
  font-size: 45px;
}

.AboutPage p {
  font-size: 11px;
}
}
