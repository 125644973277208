.Projects {
  display: flex;
  justify-content: center;
  margin: 7.5% 0 0 25%;
}

.Projects ul {
  list-style: none;
}

.Projects li {
  font-family: var(--text-paragraph);
  font-size: 12px;
  font-weight: 900;
  letter-spacing: -1px;
  transition: all 300ms ease-in-out;
}

.Projects li:hover {
  color: var(--transparent);
  cursor: pointer;
  transform: scale(1.1);
}

.Projects button {
  background: var(--transparent);
  border: none;
  font-family: var(--text-headline);
  font-size: 55px;
  letter-spacing: -3px;
  padding: 0;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.Projects .logo {
  position: relative;
  top: -14px;
}

/* Divider between ProjectPage and ProjectOne, Two, (Three & Four) */
.ProjectOne, .ProjectTwo {
  margin: -11px 0;
  overflow: hidden;
}

.ProjectOne .grid, .ProjectTwo .grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 100px;
}

.ProjectOne .project-grid-item-1-1 {
  background-image: url(./projects/project-one.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 10px 10px 0;
  transition: background-position 1s ease-in-out;
}

.ProjectTwo .project-grid-item-2-1 {
  background-image: url(./projects/project-two.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 10px 10px 0;
  transition: background-position 1s ease-in-out;
}

.ProjectOne .project-grid-item-1-1:hover, .ProjectTwo .project-grid-item-2-1:hover {
  animation: changePosition 5s infinite;
}

.ProjectOne .project-grid-item-1-2, .ProjectTwo .project-grid-item-2-2 {
  border-radius: 10px 0 0 10px;
}

.grid-light-mode {
  backdrop-filter: blur(2px);
  box-shadow: inset 0 0 100px rgba(252, 248, 219, 0.25), var(--box-shadow);
  margin: 11px 0;
}

.grid-dark-mode {
  background: rgba(65, 0, 51, 0.15);
  backdrop-filter: blur(2px);
  box-shadow: inset 0 0 100px rgba(196, 55, 90, 0.25), 0px 0px 5px rgba(53, 0, 13, 0.1);
  margin: 11px 0;
}

.ProjectOne .row, .ProjectTwo .row {
  align-items: center;
}

.ProjectOne .col-lg-4 ul, .ProjectTwo .col-lg-4 ul {
  font-family: var(--text-paragraph);
  font-size: 20px;
  font-weight: 500;
  list-style: none;
  padding: 35px 25px;
  text-transform: lowercase;
}

.ProjectOne .col-lg-4 li, .ProjectTwo .col-lg-4 li {
  line-height: 20px;
  opacity: 0.75;
}

.ProjectOne .col-lg-4 span, .ProjectTwo .col-lg-4 span {
  font-size: 13px;
  line-height: 10px;
  position: relative;
  top: -7px;
  left: 10px;
}

.ProjectOne .col-lg-4 .tool-specifications, .ProjectTwo .col-lg-4 .tool-specifications {
  font-family: var(--text-paragraph);
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  margin: -3px 0 0 0;
}

.ProjectOne .col-lg-8, .ProjectTwo .col-lg-8 {
  font-family: var(--text-paragraph);
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  padding: 35px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

.ProjectOne .project-link, .ProjectTwo .project-link {
  text-decoration: none;
  transition: all 300ms ease-in-out;
}

.ProjectOne .col-light-mode, .ProjectTwo .col-light-mode {
  border-right: 1px solid rgba(162, 37, 68, 0.1);
  transition: all 300ms ease-in-out;
}

.ProjectOne .col-dark-mode, .ProjectTwo .col-dark-mode {
  border-right: 1px solid rgba(252, 248, 219, 0.1);
  transition: all 300ms ease-in-out;
}

.ProjectTwo .link {
  margin-right: 15px;
}

.ProjectOne .link:hover, .ProjectTwo .link:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.ProjectOne .link:disabled, .ProjectTwo .link:disabled {
  border: none;
  box-shadow: none;
  margin-right: 15px;
}

.ProjectOne .project-prev-btn, .ProjectOne .project-next-btn, .ProjectTwo .project-prev-btn, .ProjectTwo .project-next-btn {
  border: none;
  font-size: 40px;
  opacity: 0.5;
  padding: 0 70px;
  transition: all 300ms ease-in-out;
}

.ProjectOne .project-prev-btn {
  cursor: none;
  opacity: 0;
}

.ProjectOne .project-next-btn:hover, .ProjectTwo .project-next-btn:hover, .ProjectTwo .project-prev-btn:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.1);
}

.project-btn-light-mode {
  color: var(--dark-color);
  transition: all 300ms ease-in-out;
}

.project-btn-dark-mode {
  color: var(--light-color);
  transition: all 300ms ease-in-out;
}

@keyframes changePosition {
  0% {
    background-position: center;
  }
  25% {
    background-position: left;
  }
  75% {
    background-position: right;
  }
  100% {
    background-position: center;
  }
}

@keyframes floatRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes floatLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@media (max-width: 1275px) {
.ProjectOne .grid, .ProjectTwo .grid {
  grid-gap: 50px;
}
}

@media (max-width: 1025px) {
.ProjectOne .grid, .ProjectTwo .grid {
  grid-template-columns: 1fr;
  grid-row-gap: 25px;
}

.ProjectOne .project-grid-item-1-1, .ProjectTwo .project-grid-item-2-1 {
  height: 485px;
  margin-right: 10%;
}

.ProjectOne .project-grid-item-1-2, .ProjectTwo .project-grid-item-2-2 {
  margin-left: 10%;
}
}

@media (max-width: 991.5px) {
.ProjectOne .col-light-mode, .ProjectTwo .col-light-mode {
  border-right: none;
  border-bottom: 1px solid rgba(162, 37, 68, 0.1);
  margin-left: 12px;
}

.ProjectOne .col-dark-mode, .ProjectTwo .col-dark-mode {
  border-right: none;
  border-bottom: 1px solid rgba(252, 248, 219, 0.1);
  margin-left: 12px;
}

.ProjectOne .col-lg-4 ul, .ProjectTwo .col-lg-4 ul {
  padding: 30px 10px 10px 11.5px;
}
}

@media (max-width: 800px) {
.Projects {
  justify-content: left;
  margin: 50px 0 0 30px;
}

.ProjectOne .col-lg-4 ul, .ProjectTwo .col-lg-4 ul {
  padding: 30px 10px 10px 7px;
}

.ProjectOne .col-lg-8, .ProjectTwo .col-lg-8 {
  margin-top: -5px;
  padding: 30px;
}
}

@media (max-width: 532px) {
.ProjectOne .link, .ProjectTwo .link {
  margin: 5px 15px 5px 0;
}
}

@media (max-width: 500px) {
.Projects button {
  font-size: 45px;
  letter-spacing: -2px;
}

.Projects .logo {
  height: 40px;
  width: 40px;
  top: -6px;
}

.ProjectOne .btn-container, .ProjectTwo .btn-container {
  display: flex;
  justify-content: space-between;
}
}

@media (max-width: 400px) {
.Projects {
  margin: 50px 0 0 0;
}
}

@media (max-width: 385px) {
.ProjectOne .link, .ProjectTwo .link {
  width: 147px;
}
}

@media (max-width: 350px) {
.ProjectOne img, .ProjectTwo img {
  left: 24%;
}
}

@media (max-width: 325px) {
.ProjectOne img, .ProjectTwo img {
  left: 22%;
}
}