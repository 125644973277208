.HomePage {
  margin: 11.5% 20px;
}

.HomePage h1 {
  font-family: var(--text-headline);
  font-size: 70px;
  letter-spacing: -4px;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
}

.HomePage h1 .sa {
  letter-spacing: -2px;
}

.HomePage h1 .ca {
  letter-spacing: -1px;
}

.HomePage h2 {
  font-family: "Unbounded", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin: -25px 0 10px 15px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}

.HomePage .link {
  margin: 10px 20px 0 20px;
  width: 115px;
}

@media (max-width: 710.5px) {
.HomePage {
  margin: 20% 20px;
}

.HomePage h1 {
  line-height: 55px;
  margin-bottom: 10px;
}

.HomePage h2 {
  margin: 0 0 20px 0;
}

.HomePage .link {
  margin: 0 20px 10px 20px;
}
}

@media (max-width: 400px) {
.HomePage {
  margin: 65px 10px;
}

.HomePage h1 {
  font-size: 100px;
  letter-spacing: -7px;
  line-height: 120px;
}

.HomePage .size-light-mode {
  font-size: 210px;
  line-height: 150px;
  -webkit-text-stroke: 1px var(--dark-color);
  transition: all 300ms ease-in-out;
}

.HomePage .size-dark-mode {
  font-size: 210px;
  line-height: 150px;
  -webkit-text-stroke: 1px var(--light-color);
  transition: all 300ms ease-in-out;
}

.HomePage .size-surname-light {
  display: block;
  font-size: 35px;
  letter-spacing: -1.5px;
  margin: -230px 0 0 140px;
  opacity: 0.5;
  -webkit-text-stroke: 0.75px var(--dark-color);
  transition: all 300ms ease-in-out;
}

.HomePage .size-surname-dark {
  display: block;
  font-size: 35px;
  letter-spacing: -1.5px;
  margin: -230px 0 0 140px;
  opacity: 0.5;
  -webkit-text-stroke: 0.75px var(--light-color);
  transition: all 300ms ease-in-out;
}

.HomePage .hide {
  display: none;
}

.HomePage h2 {
  font-size: 30px;
  letter-spacing: -2.75px;
  line-height: 30px;
  margin: 100px 20px 20px 20px;
}

.HomePage .link {
  width: 130px;
}
}

@media (max-width: 350px) {
.HomePage h2 {
  font-size: 25px;
  letter-spacing: -1.5px;
  line-height: 25px;
}
}