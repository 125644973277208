.Navbar nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 40px 80px;
}

.Navbar button {
  background: var(--transparent);
  border: none;
  padding: 0;
  transition: all 300ms ease-in-out;
}

.Navbar button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.Navbar .btn-container {
  align-items: center;
  display: flex;
}

.Navbar .theme-btn-light {
  color: var(--dark-color);
  font-size: 25px;
  margin-right: 45px;
}

.Navbar .theme-btn-dark {
  color: var(--light-color);
  font-size: 25px;
  margin-right: 45px;
}

.Navbar .menu-btn-light {
  color: var(--dark-color);
  font-size: 37px;
}

.Navbar .menu-btn-dark {
  color: var(--light-color);
  font-size: 37px;
}

.Navbar .menu-btn-close {
  font-size: 40px;
  line-height: 55px;
  margin: 0 7px 0 4.75px;
}

.Navbar .menu-btn-close:hover {
  transform: scale(1.1);
}

@media (max-width: 800px) {
.Navbar nav {
  margin: 30px 40px;
}
}

@media (max-width: 600px) {
.Navbar .theme-btn-light,
.Navbar .theme-btn-dark {
  margin-right: 30px;
}
}

@media (max-width: 400px) {
.Navbar nav {
  margin: 15px;
}

.Navbar .btn-container {
  padding-right: 5px;
}

.Navbar .theme-btn-light,
.Navbar .theme-btn-dark {
  margin-right: 20px;
}
}
